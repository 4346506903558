import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import { useCreateEmailAuthenticationCode } from 'models/Auth'
import { useEmailAuthenticationContext } from 'pages/SignIn'

export default function EmailAuthentication() {
  const { setState } = useEmailAuthenticationContext()
  const navigate = useNavigate()
  const { createEmailAuthenticationCode, isMutating } =
    useCreateEmailAuthenticationCode()

  const onFinish = async (params: { email: string }) => {
    try {
      const { data } = await createEmailAuthenticationCode(params.email)
      setState({
        email: params.email,
        emailAuthenticationCodeId: data.id,
      })
    } catch (error) {
      return
    }

    navigate('verify_code')
  }

  return (
    <>
      <Text style={{ marginTop: 16 }}>
        {t('メールアドレスを入力し、認証コードを取得してください。')}
      </Text>
      <Form
        onFinish={onFinish}
        style={{
          width: '100%',
          marginTop: 24,
        }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="email" placeholder={t('メールアドレスを入力')} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={isMutating}>
            {t('認証コードを送信する')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
