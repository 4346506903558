import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import { BankCardContent } from '@hello-ai/ar_shared/src/types/affiliate/Bank'
import { Card, Col, Row, Typography } from 'antd'
import React from 'react'

export default function BankList({
  bankAccounts,
}: {
  bankAccounts: BankCardContent[]
}) {
  return (
    <Row style={{ paddingBottom: 10 }}>
      {bankAccounts !== undefined &&
        bankAccounts.length !== 0 &&
        bankAccounts.map((bankAccount, index) => {
          return (
            <Col key={index} span={8}>
              <Card style={{ borderRadius: '8px' }}>
                {bankAccount.default_for_currency === true && (
                  <Row style={{ justifyContent: 'center' }}>
                    <Typography.Text strong style={{ color: 'brown' }}>
                      {t('デフォルト')}
                    </Typography.Text>
                  </Row>
                )}
                <Row style={{ marginTop: 2 }}>
                  <Col span={12}>{t('銀行名')}</Col>
                  <Col span={12}>{bankAccount.bank_name}</Col>
                </Row>
                <Row style={{ marginTop: 1 }}>
                  <Col span={12}>{t('口座番号')}</Col>
                  <Col span={12}>{bankAccount.last4}</Col>
                </Row>
                <Row style={{ marginTop: 1 }}>
                  <Col span={12}>{t('口座名義')}</Col>
                  <Col span={12}>{bankAccount.account_holder_name}</Col>
                </Row>
              </Card>
            </Col>
          )
        })}
    </Row>
  )
}
