import React, { useState } from 'react'
import { View } from 'react-native'
import { Outlet, useOutletContext } from 'react-router-dom'
import { Grid } from 'antd'
import { Text } from '@hello-ai/ar_shared/src/components/Text'

type EmailAuthenticationState = {
  email: string
  emailAuthenticationCodeId: string
}

type EmailAuthenticationContextValue = {
  state?: EmailAuthenticationState
  setState: (state: EmailAuthenticationState) => void
}

const { useBreakpoint } = Grid

export default function SignIn() {
  const [state, setState] = useState<EmailAuthenticationState | undefined>(
    undefined
  )
  const screens = useBreakpoint()
  const xs = screens.xs

  return (
    <View style={{ height: '100%' }}>
      <View
        style={{
          width: xs ? '100%' : 480,
          margin: 'auto',
          padding: 20,
        }}
      >
        <Text style={{ textAlign: 'center', fontWeight: '600', fontSize: 32 }}>
          AR Affiliate
        </Text>
        <Outlet context={{ state, setState }} />
      </View>
    </View>
  )
}

export function useEmailAuthenticationContext() {
  return useOutletContext<EmailAuthenticationContextValue>()
}
