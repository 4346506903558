import axios, { AxiosResponse } from 'axios'
import Qs from 'qs'
import Config from 'constants/Config'
import { AUTH_KEY } from 'models/Auth'

export const headers = {
  Accept: 'application/vnd.autoreserve.com+json; version=1',
  'Content-Type': 'application/json',
  'X-ForR-Platform': 'web',
}

const instance = axios.create({
  baseURL: Config.apiUrl,
  headers,
})

instance.interceptors.request.use((config) => {
  config.paramsSerializer = {
    encode(value) {
      return Qs.parse(value)
    },
    serialize(params) {
      return Qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: true,
        skipNulls: true,
      })
    },
  }

  const auth = JSON.parse(localStorage.getItem(AUTH_KEY) ?? 'null')

  if (config.headers !== undefined) {
    if (auth?.token != null) {
      config.headers.Authorization = `Token token=${auth.token}`
      config.headers['X-AR-Token'] = auth.token
    } else {
      delete config.headers.Authorization
      delete config.headers['X-AR-Token']
    }
  }

  return config
})

type ResponseWrapper<T> = Promise<{
  response?: AxiosResponse<T>
  error?: unknown
}>

export async function wrapResponse<T>(
  promise: Promise<AxiosResponse<T>>
): ResponseWrapper<T> {
  return await promise
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const { isAxiosError } = axios

export default instance
