import { AuthClient } from '@hello-ai/proto/src/gen/auto_reserve/affiliate/auth/auth_service.client'
import axios, { isAxiosError } from 'modules/axios'
import useSWR, { mutate, useSWRMutation } from 'modules/swr'

import { getErrorMessage } from 'modules/getErrorMessage'
import { createRpcService } from 'modules/rpc'

import { message } from 'antd'
import { useCallback, useEffect } from 'react'

export const authService = createRpcService(AuthClient)

export type Token = string
export interface Auth {
  id: number
  token: Token
}

type CreateEmailAuthenticationCodeResponse = {
  id: string
  authentication_type: 'login' | 'signup'
}

export const AUTH_KEY = 'ForAffiliateWeb:auth' as const

export function onError(error: unknown) {
  if (isAxiosError(error) && error.response?.status === 401) {
    signOut()
  }
  message.error(getErrorMessage(error))
}

export async function signOut() {
  localStorage.removeItem(AUTH_KEY)
  mutate(AUTH_KEY, null, false)
}

export function useCreateEmailAuthenticationCode() {
  const { trigger, isMutating, error } = useSWRMutation(
    '/email_authentication_codes',
    (url, { arg }: { arg: string }) =>
      axios.post<CreateEmailAuthenticationCodeResponse>(url, { email: arg })
  )

  useEffect(() => {
    if (error != null) {
      onError(error)
    }
  }, [error])

  return {
    createEmailAuthenticationCode: trigger,
    isMutating,
  }
}

export function useSignInWithEmailAuthenticationCode(
  emailAuthenticationCodeId: string
) {
  const { trigger, isMutating, error } = useSWRMutation(
    `/email_authentication_codes/${emailAuthenticationCodeId}/sessions`,
    (url, { arg }: { arg: string }) => axios.post<Auth>(url, { code: arg })
  )

  useEffect(() => {
    if (error != null) {
      onError(error)
    }
  }, [error])

  const signIn = useCallback(
    async (code: string) => {
      const { data } = await trigger(code)
      const auth = { id: data.id, token: data.token }

      localStorage.setItem(AUTH_KEY, JSON.stringify(auth))
      await mutate(AUTH_KEY, auth)
    },
    [trigger]
  )

  return {
    signIn,
    isMutating,
  }
}

const authData = JSON.parse(localStorage.getItem(AUTH_KEY) ?? 'null')

export function useAuth() {
  const { data: auth, mutate } = useSWR<Auth | null>(AUTH_KEY, null, {
    fallbackData: authData,
  })

  return {
    auth: auth ?? undefined,
    mutate,
  }
}

export function useToken() {
  const { auth } = useAuth()
  return auth?.token ?? null
}
