// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/affiliate/user/user_resource.proto" (package "auto_reserve.affiliate.user", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.affiliate.user.UserResource
 */
export interface UserResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: string name = 4;
   */
  name: string;
  /**
   * @generated from protobuf field: string email = 5;
   */
  email: string;
  /**
   * @generated from protobuf field: string username = 6;
   */
  username: string;
  /**
   * @generated from protobuf field: string country_code = 7;
   */
  countryCode: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class UserResource$Type extends MessageType<UserResource> {
  constructor() {
    super("auto_reserve.affiliate.user.UserResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
      { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
      { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: "country_code",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<UserResource>): UserResource {
    const message = {
      id: 0,
      name: "",
      email: "",
      username: "",
      countryCode: "",
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UserResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UserResource,
  ): UserResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* google.protobuf.Timestamp created_at */ 2:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 3:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* string name */ 4:
          message.name = reader.string();
          break;
        case /* string email */ 5:
          message.email = reader.string();
          break;
        case /* string username */ 6:
          message.username = reader.string();
          break;
        case /* string country_code */ 7:
          message.countryCode = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UserResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* google.protobuf.Timestamp created_at = 2; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 3; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string name = 4; */
    if (message.name !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.name);
    /* string email = 5; */
    if (message.email !== "")
      writer.tag(5, WireType.LengthDelimited).string(message.email);
    /* string username = 6; */
    if (message.username !== "")
      writer.tag(6, WireType.LengthDelimited).string(message.username);
    /* string country_code = 7; */
    if (message.countryCode !== "")
      writer.tag(7, WireType.LengthDelimited).string(message.countryCode);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.affiliate.user.UserResource
 */
export const UserResource = new UserResource$Type();
