import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import { useEmailAuthenticationContext } from 'pages/SignIn'
import { useSignInWithEmailAuthenticationCode } from 'models/Auth'

export default function SignInVerifyCode() {
  const { state } = useEmailAuthenticationContext()
  const navigate = useNavigate()
  const { signIn, isMutating } = useSignInWithEmailAuthenticationCode(
    state?.emailAuthenticationCodeId ?? ''
  )

  if (state == null) {
    return <Navigate to="/sign_in" replace />
  }

  const onFinish = async (params: { code: string }) => {
    try {
      await signIn(params.code)
    } catch (error) {
      return
    }

    navigate('/')
  }

  return (
    <>
      <Text style={{ marginTop: 16 }}>
        {t(
          '{{text}}宛にメールを送信しました。\n届いた認証コードを入力してください。',
          { text: state.email }
        )}
      </Text>
      <Form
        onFinish={onFinish}
        style={{
          width: '100%',
          marginTop: 24,
        }}
      >
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input inputMode="numeric" placeholder={t('認証コードを入力')} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={isMutating}>
            {t('ログイン')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
