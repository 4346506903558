import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import {
  StripeAccount,
  UploadFileListState,
} from '@hello-ai/ar_shared/src/types/affiliate/Identification'
import { Col, Form, Input, Row, Select } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import DateSelect from './DateSelect'
import FileUpload from './FileUpload'

export default function IndividualFormItems({
  stripeAccount,
  documentFrontFileList,
  documentBackFileList,
  setDocumentFrontFileList,
  setDocumentBackFileList,
}: {
  stripeAccount: StripeAccount | undefined
  documentFrontFileList: UploadFileListState
  documentBackFileList: UploadFileListState
  setDocumentFrontFileList: React.Dispatch<
    React.SetStateAction<UploadFileListState>
  >
  setDocumentBackFileList: React.Dispatch<
    React.SetStateAction<UploadFileListState>
  >
}) {
  return (
    <>
      <Input.Group>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t('姓(漢字)')}
              name="last_name_kanji"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('名(漢字)')}
              name="first_name_kanji"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Input.Group>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t('姓(かな)')}
              name="last_name_kana"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('名(かな)')}
              name="first_name_kana"
              labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Form.Item label={t('電話番号')} name="phone">
        <Input placeholder="090-1234-5678" />
      </Form.Item>
      <Form.Item
        label={t('生年月日')}
        style={{ marginBottom: 0, paddingBottom: 0 }}
        name="birthday"
      >
        <Input.Group>
          <Form.Item
            name={['birthday', 'year']}
            style={{ display: 'inline-block', width: 100 }}
          >
            <DateSelect
              start={1900}
              end={dayjs().year()}
              placeholder={t('年')}
            />
          </Form.Item>
          <Form.Item
            name={['birthday', 'month']}
            style={{ display: 'inline-block', width: 80 }}
          >
            <DateSelect start={1} end={12} placeholder={t('月')} />
          </Form.Item>
          <Form.Item
            name={['birthday', 'day']}
            style={{ display: 'inline-block', width: 80 }}
          >
            <DateSelect start={1} end={31} placeholder={t('日')} />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item label={t('性別')} name="gender">
        <Select>
          <Select.Option value="male">{t('男性')}</Select.Option>
          <Select.Option value="female">{t('女性')}</Select.Option>
        </Select>
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item
            label={t('身分証明書(表)')}
            name={'document_front'}
            labelCol={{ span: 8 }}
          >
            <FileUpload
              stripeDocumentId={stripeAccount?.document_front}
              fileList={documentFrontFileList}
              setFileList={setDocumentFrontFileList}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('身分証明書(裏)')}
            name={'document_back'}
            labelCol={{ span: 8 }}
          >
            <FileUpload
              stripeDocumentId={stripeAccount?.document_back}
              fileList={documentBackFileList}
              setFileList={setDocumentBackFileList}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={t('郵便番号')} name="postal_code">
        <Input />
      </Form.Item>
      {stripeAccount?.postal_code != null && (
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                label={t('都道府県')}
                labelCol={{ span: 8 }}
                name="state"
              >
                <Input readOnly disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('市区町村')}
                labelCol={{ span: 8 }}
                name="city"
              >
                <Input readOnly disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={t('街・丁目')}
                labelCol={{ span: 8 }}
                name="town_kanji"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('街・丁目(かな)')}
                labelCol={{ span: 8 }}
                name="town_kana"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={t('番地')}
                labelCol={{ span: 8 }}
                name="line1_kanji"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('番地(かな)')}
                labelCol={{ span: 8 }}
                name="line1_kana"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={t('建物・部屋番号')}
                labelCol={{ span: 8 }}
                name="line2_kanji"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('建物・部屋番号(かな)')}
                labelCol={{ span: 8 }}
                name="line2_kana"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}
