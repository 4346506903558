import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import {
  MediumResource,
  MediumResource_Status,
} from '@hello-ai/proto/src/gen/auto_reserve/affiliate/medium/medium_resource'
import { Button, Space, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { getStatusName } from 'models/Medium'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const { Text } = Typography

export function MediumList({ media }: { media: MediumResource[] | undefined }) {
  const navigate = useNavigate()

  const isJapaneseUser = media?.[0]?.mediumUsers[0]?.user?.countryCode === 'jp'

  const columns: ColumnsType<MediumResource> = [
    {
      title: t('ステータス'),
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Text>{getStatusName(text)}</Text>,
    },
    {
      title: t('サイト名'),
      dataIndex: 'name',
      key: 'name',
      render: (_, medium: MediumResource) => (
        <Link to={`/media/${medium.id}`}>{medium.name}</Link>
      ),
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (_, medium: MediumResource) => (
        <Text>{medium.url ? medium.url : medium.domain}</Text>
      ),
    },
    {
      title: 'slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: t('アクション'),
      key: 'action',
      fixed: 'right',
      render: (_, medium: MediumResource) => (
        <Space size="middle">
          {medium.status === MediumResource_Status.CERTIFIED && (
            <Button onClick={() => navigate(`/media/${medium.id}`)}>
              {t('詳細')}
            </Button>
          )}
        </Space>
      ),
    },
  ]

  if (isJapaneseUser) {
    columns.push(
      {
        title: t('本人確認'),
        key: 'identification',
        fixed: 'right',
        render: (_, medium: MediumResource) => (
          <Space size="middle">
            {medium.status === MediumResource_Status.CERTIFIED && (
              <Button
                onClick={() => navigate(`/media/${medium.id}/identification`)}
              >
                {t('編集')}
              </Button>
            )}
          </Space>
        ),
      },
      {
        title: t('口座登録'),
        key: 'bank_account_register',
        fixed: 'right',
        render: (_, medium: MediumResource) => (
          <Space size="middle">
            {medium.status === MediumResource_Status.CERTIFIED && (
              <Button onClick={() => navigate(`/media/${medium.id}/bank`)}>
                {t('編集')}
              </Button>
            )}
          </Space>
        ),
      }
    )
  }

  return (
    <Table
      rowKey={(record) => record.id}
      columns={columns}
      dataSource={media}
      pagination={false}
      scroll={{ x: true }}
    />
  )
}
